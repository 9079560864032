import { Line } from '@onesy/ui-react';

import { NoResults } from 'ui';

const Root = () => {
  return (
    <Line
      align='center'

      justify='center'

      flex

      fullWidth
    >
      <NoResults>
        No page found
      </NoResults>
    </Line>
  );
};

export default Root; 
