import { UrlShortener } from '@onesy/api';

import { IAdditional, IResponse, IUrlShortenerAnalytic } from '@onesy/sdk/other';

import { BaseService } from './base';
import MainService from './main';

class UrlShortenerService extends BaseService<UrlShortener> {

  public constructor() {
    super('urlShorteners');
  }

  public async analytic(body: IUrlShortenerAnalytic, options?: IAdditional): Promise<IResponse> {
    return MainService.sdk[this.route as 'urlShorteners']?.analytic(body, options);
  }

}

export default new UrlShortenerService();
