import { Socket, io } from 'socket.io-client';

import { wait } from '@onesy/utils';
import { IResponse } from '@onesy/sdk/other';

import config from '../config';
import StorageService from './storage';
import LogService from './log';

export interface EmployeeOnlineValue {
  organization: {
    id: string;
    name: string;
  };
  employee: {
    id: string;
    name: string;
  };
  coordinates: [number, number];
  added_at: number;
}

export class SocketService {
  public client: Socket = undefined as any;

  public init() {
    const query = {
      'user-id': StorageService.get('device-id'),
      'device-id': StorageService.get('device-id')
    };

    this.client = io(config.value.apis.sockets.url, {
      query,
      transports: ['websocket']
    });

    console.log('init', query);

    // events
    this.client.on('error', (...args) => {
      LogService.debug('Socket error', ...args);
    });

    this.client.on('connect', (...args) => {
      LogService.debug('Socket connected', this.client.connected, ...args);
    });

    this.client.on('disconnect', (...args) => {
      LogService.debug('Socket disconnected', this.client.connected, ...args);
    });

    this.client.on('reconnect', (...args) => {
      LogService.debug('Socket reconnected', this.client.connected, ...args);
    });

    // api
    this.client.on('employees-online', (value: EmployeeOnlineValue) => {
      LogService.debug('Socket event: employees-online', value);

      this.update(value, 'employees-online');
    });
  }

  public disconnect() {
    if (this.client && this.client.connected) {
      this.client.disconnect();
    }
  }

  // employee online 
  public async updateEmployeeOnline(value: EmployeeOnlineValue) {
    const response = await this.emit('employees-online', { value });

    return response;
  }

  // methods
  public update(value: any, version: string = 'online', add = true, all = false) {

  }

  public async emit(version: string, data: any): Promise<IResponse> {
    if (!this.client.connected) {
      this.init();

      await wait(1400);
    }

    const result: any = await new Promise((resolve, reject) => {
      this.client.emit(version, data, resolve);
    });

    const response: IResponse = {
      status: result?.meta?.status,
      response: result
    };

    return response;
  }
}

export default new SocketService();
