import { Organization } from '@onesy/api';

import { BaseService } from './base';

class OrganizationService extends BaseService<Organization> {

  public constructor() {
    super('organizations');
  }

}

export default new OrganizationService();
