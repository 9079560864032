import React from 'react';

import { Snackbars } from '@onesy/ui-react';
import { ISnackbars } from '@onesy/ui-react/Snackbars/Snackbars';
import { classNames } from '@onesy/style-react';

const Element = React.forwardRef((props: ISnackbars, ref: any) => {
  const {
    className,

    ...other
  } = props;

  return (
    <Snackbars
      ref={ref}

      position='bottom'

      alignment='end'

      className={classNames([
        className
      ])}

      {...other}
    />
  );
});

Element.displayName = 'onesy-Snackbars';

export default Element;
