import { IOptionsRequest } from '@onesy/request/OnesyRequest';
import OnesySDK from '@onesy/sdk';
import { IOnesySDKOptions } from '@onesy/sdk/other';
import OnesySub from '@onesy/subscription';
import { merge } from '@onesy/utils';

import config from 'config';
import { StorageService } from 'services';

class MainService {
  public initial = new OnesySub();
  public sdk: OnesySDK = new OnesySDK({
    onesy_token: config.value.onesy_token,

    apis: {
      api: {
        url: config.value.apis.api.url
      },
      api_media_read: {
        url: config.value.apis.api_media_read.url
      },
      api_media_write: {
        url: config.value.apis.api_media_write.url
      }
    }
  });

  public init(options_: IOnesySDKOptions = {}): OnesySDK {
    const options = merge(options_, {
      onesy_token: config.value.onesy_token,

      apis: {
        api: {
          url: config.value.apis.api.url
        },
        api_media_read: {
          url: config.value.apis.api_media_read.url
        },
        api_media_write: {
          url: config.value.apis.api_media_write.url
        }
      }
    });

    this.sdk = new OnesySDK(options);

    this.sdk.requestService.onesyRequest.interceptors.request.pre.subscribe((value: IOptionsRequest) => {
      const token = StorageService.get('token');

      if (token) value.request!.headers!['Authorization'] = `onesy ${token}`;

      const deviceID = StorageService.get('device-id');

      if (deviceID) value.request!.headers!['device-id'] = deviceID;

      const employee = StorageService.get('employee');

      if (employee) value.request!.headers!['employee'] = employee?.id || employee?._id || employee;

      const customer = StorageService.get('customer');

      if (customer) value.request!.headers!['customer'] = customer?.id || customer?._id || customer;

      const website = StorageService.get('website');

      if (website) {
        value.request!.headers!['website-id'] = website?.id || website?._id;
      }
    });

    return this.sdk;
  }
}

export default new MainService();
