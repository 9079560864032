import React from 'react';
import { useParams } from 'react-router-dom';

import { textToInnerHTML } from '@onesy/utils';
import { Button, Line, Link, print, Properties, RoundProgress, Table, TableBody, TableCell, TableHead, TableRow, Type, useSnackbars } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';
import { OnesyDate, format } from '@onesy/date';
import { Customer, Invoice, Organization, Service } from '@onesy/api';

import { Meta, NoResults, Paper } from 'ui';
import { InvoiceService } from 'services';
import { formatNumber, formats, getErrorMessage } from 'utils';

const useStyle = style(theme => ({
  root: {

  },

  logo: {
    height: 50,
    width: 'auto'
  },

  printOnly: {
    display: 'none'
  },

  '@media print': {
    $table: {
      border: `1px solid #ddd`,
      boxShadow: 'none !important',

      '& .onesy-Divider-root': {
        opacity: '1 !important',
        borderBottom: '0.5px solid #ddd !important'
      }
    },

    $printOnly: {
      display: 'block !important'
    },

    $hidePrint: {
      display: 'none !important'
    }
  },

  ...theme.classes(theme)
}), { name: 'onesy-public-URL' });

const Element = () => {
  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const params = useParams();

  const [loaded, setLoaded] = React.useState(false);
  const [invoice, setInvoice] = React.useState<Invoice>();

  const refs = {
    paper: React.useRef<HTMLDivElement>(undefined)
  };

  const init = React.useCallback(async () => {
    const id = params.id!;

    const result = await InvoiceService.getPublic(id);

    if (result.status >= 400) {
      snackbars.add({
        primary: getErrorMessage(result),
        color: 'error'
      });
    }
    else {
      setInvoice(result.response.response);
    }

    setLoaded(true);
  }, [params]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  let title = 'Invoice';

  const organization = invoice?.organization as Organization;

  const organizationName = textToInnerHTML(organization?.name || '');

  const organizationLogo = organization?.settings?.logos?.logo_light?.url;

  const customer = invoice?.customer as Customer;

  const customerName = textToInnerHTML(customer?.name || '');

  if (invoice) {
    title = `${organizationName} invoice for ${customerName}`;
  }

  const currency = organization?.settings?.currency || 'EUR';

  const valid = loaded && !!(invoice && invoice.items.length);

  const subtotal = React.useMemo(() => {
    if (!valid) return 0;

    return invoice.items.reduce((result, item) => {
      return result + (((item.service as Service).price?.value || 0) * (item.quantity || 0));
    }, 0);
  }, [invoice, valid]);

  const tax = React.useMemo(() => {
    if (!valid) return 0;

    return invoice.tax ? (subtotal * (invoice.tax / 100)) : 0;
  }, [invoice, subtotal, valid]);

  const total = React.useMemo(() => {
    if (!valid) return 0;

    return subtotal + tax;
  }, [invoice, subtotal, tax, valid]);

  const onDownload = React.useCallback(() => {
    const paper = refs.paper.current!.children[0];

    print(paper as any);
  }, []);

  return <>
    <Meta>
      <title>{title}</title>
    </Meta>

    <Line
      gap={0}

      direction='column'

      align='center'

      justify='center'

      flex

      fullWidth

      className={classNames([
        'onesy-page-Invoice',

        classes.root,
        !invoice && classes.wrapper
      ])}
    >
      {!loaded && <RoundProgress />}

      {loaded && !valid && <NoResults />}

      {loaded && valid && (
        <Line
          gap={1}

          align='center'

          flex

          fullWidth
        >
          <Paper
            ref={refs.paper}

            gap={4}

            meta={false}

            className={classes.print}
          >
            <Line
              gap={2.5}

              align='center'

              fullWidth
            >
              <Line
                className={classes.hidePrint}
              >
                {organizationLogo ? <img src={organizationLogo} alt='logo' className={classes.logo} /> : <Type version='t1'>{organizationName}</Type>}
              </Line>

              <Type version='t1' className={classes.printOnly}>{organizationName}</Type>

              <Line
                gap={1}

                align='center'

                fullWidth
              >
                <Type
                  version='d3'

                  weight={500}
                >
                  Invoice
                </Type>

                <Line
                  gap={0.5}

                  align='center'

                  fullWidth
                >
                  <Type
                    version='t1'

                    weight={500}
                  >
                    {invoice?.invoice_id}
                  </Type>

                  <Type
                    version='b1'
                  >
                    Due on: {format(new OnesyDate(invoice!.due_at), formats.date)}
                  </Type>
                </Line>
              </Line>
            </Line>

            <Line
              align='flex-end'

              className={classes.hidePrint}
            >
              <Button
                onClick={onDownload}
              >
                Download PDF
              </Button>
            </Line>

            <Line
              gap={1}

              fullWidth
            >
              <Type
                version='b2'
              >
                From:
              </Type>

              <Line
                gap={0}

                fullWidth
              >
                <Type
                  version='t2'
                >
                  {organizationName}
                </Type>

                {(organization?.location?.country || organization?.location?.city) && (
                  <Type
                    version='b1'
                  >
                    {[organization?.location?.city, organization?.location?.country].filter(Boolean).join(', ')}
                  </Type>
                )}

                {organization?.location?.name && (
                  <Type
                    version='b1'
                  >
                    {organization.location.name}
                  </Type>
                )}

                {organization?.owner?.email && (
                  <Line
                    gap={1}

                    direction='row'

                    align='center'
                  >
                    <Type
                      version='b1'
                    >
                      Email:
                    </Type>

                    <Link
                      version='b1'

                      href={`mailto:${organization?.owner.email}`}
                    >
                      {organization?.owner.email}
                    </Link>
                  </Line>
                )}
              </Line>
            </Line>

            <Line
              gap={1}

              fullWidth
            >
              <Type
                version='b2'
              >
                Billed to:
              </Type>

              <Line
                gap={0}

                fullWidth
              >
                <Type
                  version='t2'
                >
                  {textToInnerHTML(customer?.name)}
                </Type>

                {customer?.email && (
                  <Line
                    gap={1}

                    direction='row'

                    align='center'
                  >
                    <Type
                      version='b1'
                    >
                      Email:
                    </Type>

                    <Link
                      version='b1'

                      href={`mailto:${customer.email}`}
                    >
                      {customer.email}
                    </Link>
                  </Line>
                )}

                {(customer?.location?.country || customer?.location?.city) && (
                  <Type
                    version='b1'
                  >
                    {[customer?.location?.city, customer?.location?.country].filter(Boolean).join(', ')}
                  </Type>
                )}

                {customer?.location?.name && (
                  <Type
                    version='b1'
                  >
                    {customer.location.name}
                  </Type>
                )}
              </Line>
            </Line>

            <Line
              gap={1.5}

              fullWidth
            >
              <Table
                color='themed'

                className={classes.table}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Type
                        version='l1'
                      >
                        Items
                      </Type>
                    </TableCell>

                    <TableCell>
                      <Type
                        version='t3'
                      >
                        <Line
                          gap={0.75}

                          direction='row'

                          align='center'
                        >
                          <Type
                            version='t3'
                          >
                            Amount in
                          </Type>

                          <Type
                            version='t3'

                            weight={300}
                          >
                            {currency}
                          </Type>
                        </Line>
                      </Type>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {invoice!.items.map((item, index: number) => (
                    <TableRow
                      key={index}
                    >
                      <TableCell>
                        <Type
                          version='b1'
                        >
                          {textToInnerHTML(item.service!.name)} x {item.quantity}
                        </Type>
                      </TableCell>

                      <TableCell>
                        <Type
                          version='b1'
                        >
                          {formatNumber(((item.service as Service).price?.value * item.quantity) || 0)}
                        </Type>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <Line
                gap={0}

                align='flex-end'

                fullWidth
              >
                <Properties
                  rowGap={0.5}

                  values={[
                    { name: <Type version='l2' weight={400} whiteSpace='nowrap'>Subtotal</Type>, value: <Type version='b2' align='right'>{formatNumber(subtotal)} {currency}</Type> },
                    { name: <Type version='l2' weight={400} whiteSpace='nowrap'>Tax rate</Type>, value: <Type version='b2' align='right'>{invoice.tax ? formatNumber(invoice.tax) : 0}%</Type> },
                    { name: <Type version='l2' weight={400} whiteSpace='nowrap'>Tax</Type>, value: <Type version='b2' align='right'>{formatNumber(tax)} {currency}</Type> },
                    { name: <Type version='l2' weight={400} whiteSpace='nowrap'>Total</Type>, value: <Type version='b2' align='right'>{formatNumber(total)} {currency}</Type> }
                  ]}

                  fullWidth={false}
                />
              </Line>
            </Line>

            {invoice?.note && (
              <Line
                gap={2}

                align='center'

                fullWidth

                style={{
                  marginTop: 32
                }}
              >
                <Type
                  version='b1'

                  align='center'

                  whiteSpace='pre-wrap'

                  dangerouslySetInnerHTML={{
                    __html: textToInnerHTML(invoice.note)
                  }}
                />
              </Line>
            )}

            <Line
              align='center'

              fullWidth
            >
              <Type
                version='b2'
              >
                Issued on: {format(new OnesyDate(invoice!.issued_at), formats.date)}
              </Type>
            </Line>
          </Paper>
        </Line>
      )}
    </Line>
  </>;
};

export default Element;
